import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContentPrimary from "../components/blockContentPrimary"
import { CustomSection, CustomSubtitle } from "../components/CustomElements"
import TagsSection from "../components/tags/TagsSection"
import ButtonTemplate from "../components/buttons/ButtonTemplate"

const JobPost = ({ data }) => {
  const {
    jobTitle,
    summary,
    files,
    _rawDetails: details,
    _rawApplyInstructions: applyInstructions,
    applicationLink,
    applicationDeadline,
    refs,
  } = data.sanityJobOpenings

  return (
    <Layout>
      {/* Change this to be dynamic based on title of job posting */}
      <SEO title={jobTitle} />
      {/* Main conent of jobs page */}
      <div className="flex flex-col items-center mt-10 md:mt-20 mx-3">
        <h1 className="text-center text-blue-dark ">{jobTitle}</h1>

        <p className="text-center">
          <strong>Deadline:&ensp;</strong>
          {applicationDeadline || (
            <span className="italic">Open until filled</span>
          )}
        </p>

        <div className="mx-10 grid justify-center"></div>

        <div className="md:w-3/4 mb-5">
          {summary && (
            <CustomSection>
              <CustomSubtitle>Summary</CustomSubtitle>
              <p className="leading-tight text-justify md:text-left">
                {summary}
              </p>
            </CustomSection>
          )}

          {details && (
            <CustomSection>
              <CustomSubtitle>Details</CustomSubtitle>
              <BlockContentPrimary blockData={details} />
            </CustomSection>
          )}

          {applyInstructions && (
            <CustomSection>
              <CustomSubtitle>How to Apply</CustomSubtitle>
              <BlockContentPrimary blockData={applyInstructions} />
            </CustomSection>
          )}

          {applicationLink && (
            <CustomSection>
              <CustomSubtitle>Related Link</CustomSubtitle>
              <a
                className="blockContent__link"
                href={applicationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {applicationLink}
              </a>
            </CustomSection>
          )}

          {/* Only display if the files data array has one or more entries */}
          {files.length >= 1 && (
            <CustomSection className="mt-10 pt-5 border-t border-gray-dark">
              <CustomSubtitle>Files & Downloads</CustomSubtitle>
              <ol className="text-xl list-decimal">
                {files.map(({ asset }) => {
                  const { url, originalFilename, id } = asset

                  return (
                    <li key={`jobFile-${id}`}>
                      <a
                        href={`${url}?dl=`}
                        className="underline hover:text-blue-dark visited:text-purple-600"
                      >
                        {originalFilename}
                      </a>
                    </li>
                  )
                })}
              </ol>
            </CustomSection>
          )}

          <CustomSection>
            <TagsSection refs={refs} />
          </CustomSection>
        </div>

        <ButtonTemplate
          to="/jobs"
          text="View All Jobs"
          className="text-white uppercase tracking-wide"
          index={1}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query JobPageQuery($slug: String!) {
    sanityJobOpenings(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      jobTitle
      summary
      files {
        asset {
          id
          url
          originalFilename
        }
      }
      _rawDetails(resolveReferences: { maxDepth: 10 })
      _rawApplyInstructions(resolveReferences: { maxDepth: 10 })
      applicationLink
      applicationDeadline(formatString: "MMMM Do, YYYY")
      refs {
        communityReference {
          communityName
          slug {
            current
          }
        }
        departmentReference {
          departmentName
          slug {
            current
          }
        }
      }
    }
  }
`

export default JobPost
