import React from "react"
import { Link } from "gatsby"

const Tags = ({ tags }) => {
  return (
    <ul className="list-none m-0 flex flex-wrap gap-y-2 ">
      {tags.map(tag => (
        <li>
          <Link
            to={tag.handle}
            className="underline m-0 mx-1 border-2 border-gray-dark hover:border-blue-linkDark rounded-md p-1 text-gray-dark hover:text-blue-linkDark "
          >
            {tag.text}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Tags
