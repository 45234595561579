import React from "react"
import Tags from "./Tags"

// Structure of refs should follow the partial graphql query structure:
// refs {
//   communityReference {
//     communityName
//     slug {
//       current
//     }
//   }
//   departmentReference {
//     departmentName
//     slug {
//       current
//     }
//   }
// }

const TagsSection = ({ refs = null }) => {
  let communityTags = null
  let deptTags = null

  if (refs?.communityReference && refs.communityReference.length >= 1) {
    communityTags = refs.communityReference.map(item => {
      const commTagInfo = {
        handle: `/communities/${item?.slug?.current}`,
        text: item?.communityName,
      }
      return commTagInfo
    })
  }

  if (refs?.departmentReference && refs.departmentReference.length >= 1) {
    deptTags = refs.departmentReference.map(item => {
      const deptTagInfo = {
        handle: `/departments/${item?.slug?.current}`,
        text: item?.departmentName,
      }
      return deptTagInfo
    })
  }

  return (
    <section>
      {(deptTags || communityTags) && <h2>Tags</h2>}
      <div className="">
        {deptTags && (
          <div className="justify-self-center mb-8">
            <h3 className="text-xl pb-2">Departments</h3>
            <Tags tags={deptTags} />
          </div>
        )}

        {communityTags && (
          <div className=" justify-self-center mb-8">
            <h3 className="text-xl pb-2">Communities</h3>
            <Tags tags={communityTags} />
          </div>
        )}
      </div>
    </section>
  )
}

export default TagsSection
